<template>
  <ValidationObserver ref="saveDataValidate">
    <form @submit.prevent="saveData">
      <b-card title="Update Banner Content">
        <div class="form-group form-row">
          <label class="col-4 col-lg-4 col-form-label">Banner</label>
          <div class="col">
            <img :src="image_thumbnail" style="max-width: 60%; float: left"/>
            <div v-if="formPayload.image" class="mt-1 relative">
              
              <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle ml-2"
                @click="cancelThumbnail()"
              >
              <feather-icon icon="XIcon" />
              </b-button>
              <small class="text-danger">{{ messageError }}</small>
            </div>
            <div v-else>
              <validation-provider
                #default="{ errors }"
                name="thumbnail"
                rules="required"
              >
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @change="onFileSelectedThumbnail"
                  accept="image/png, image/gif, image/jpeg"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <!-- <span class="text-danger" v-if="!errors.length"
                    ><small>* file harus berdimensi 3000 x 1000</small></span
                  > -->
              </validation-provider>
            </div>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-4 col-form-label">Link</label>
          <div class="col">
            <validation-provider
              #default="{ errors }"
              name="link"
              rules="required"
            >
              <b-form-input v-model="formPayload.link" placeholder="Link" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>

        <div class="form-group form-row">
          <label class="col-4 col-form-label" for="provinsi">Posisi Banner:</label>
          <div class="col">
            <validation-provider
              #default="{ errors }"
              name="placement"
              rules="required"
            >
            <v-select
              id="placementType" 
              v-model="formPayload.placement"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              placeholder="Pilih Posisi Bannner"
              :options="placementType"
              :reduce="placementType => placementType.value"
              :multiple="true"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
      </b-card>
      <b-col cols="12" class="p-0 mt-2">
        <button
          class="btn waves-effect waves-float waves-light btn-primary"
          v-if="!isLoading"
        >
          Save
        </button>
        <button
          v-else
          disabled
          variant="primary"
          class="btn waves-effect waves-float waves-light btn-primary"
        >
          <div
            class="spinner-border text-light"
            role="status"
            style="width: 15px; height: 15px;"
          ></div>
          Save
        </button>
        &nbsp; &nbsp;
        <a
          @click="handleBackButton()"
          variant="secondary"
          class="btn waves-effect waves-float waves-light btn-secondary"
        >
          Cancel
        </a>
      </b-col>
    </form>
  </ValidationObserver>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormFile,
  BButton,
  BSpinner,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { VueEditor } from "vue2-editor";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
} from "vee-validate";
import { successNotification, errorNotification } from "@/auth/utils";
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"],
  },
});
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSpinner,
    BFormFile,
    BFormInput,
    BFormTextarea,
    VueEditor,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      isLoading: false,
      formPayload: {
        image: "",
        link: "",
        placement: ""
      },
      messageError : "",
      image_thumbnail: "",
      bannerImage: "",
      fileVendorImage: [],
      filesThumbnail: [],
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic"],
        [false, false],
        [false],
        [false],
      ],
      snowOption: {
        theme: "snow",
      },
      placementType: [
        { 
            title: 'Homepage',
            value: 'homepage',
        },
        { 
            title: 'Detail Artikel',
            value: 'detail_artikel',
        },
        { 
            title: 'Cari Vendor',
            value: 'cari_vendor',
        }
      ],
      validations: {},
    };
  },
  setup(props) {
    return {
      successNotification,
      errorNotification,
    };
  },

  watch: {},

  created() {
    this.loadData();
  },

  methods: {
    handleBackButton() {
      history.back();
    },
    cancelThumbnail() {
      this.image_thumbnail = "";
      this.formPayload.image = null;
    },
    onFileSelectedThumbnail(event) {
      this.formPayload.image = event.target.files[0];
      this.showfileThumbnailImage(event.target.files[0]);
    },
    showfileThumbnailImage(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        
        img.onload = (e) => {
          if (img.width == 600 && img.height == 186) {
            this.messageError = null;
            this.image_thumbnail = event.target.result;
          } else {
            this.messageError = "Ukuran dimensi gambar harus 600x186";
            this.image_thumbnail = event.target.result;
          }
        }
        
        this.messageError = null;
        this.image_thumbnail = event.target.result;
      };
      reader.readAsDataURL(file);
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        form.append(key, this.formPayload[key]);
      }
      return form;
    },
    saveData() {
      console.log(this.messageError)
      if (!this.messageError) {
        this.$refs.saveDataValidate.validate().then((success) => {
          if (success) {
            const slugs = this.$route.params.slug;
            var form = this.preparePayload();
            this.isLoading = true;
            form.append("_method", "PATCH");
            this.$http
              .post(`/admin/banner-content/${slugs}`, form, {
                headers: { "content-type": "multipart/form-data" },
              })
              .then((response) => {
                successNotification(this, "Success", "Banner Content Updated!");
                this.$router.push({ name: "banner-content" });
                this.isLoading = false;
              })
              .catch((error) => {
                this.validations = error.response.data.meta.validations;
                this.isLoading = false;
                if (error.response.data.meta.messages.length > 0) {
                  errorNotification(
                    this,
                    "Oops!",
                    error.response.data.meta.messages
                  );
                }
                if (error.response.data.meta.validations) {
                  // this.validations = error.response.data.meta.validations;
                  // errorNotification(
                  //   this,
                  //   "Nama",
                  //   this.validations.name.join(" ")
                  // );
                }
                this.isLoading = false;
              });
          }
        });
      } else {
        this.messageError = "Gambar Harus berukuran 648x201";
      }
    },
    loadData() {
      const params = this.$route.params.slug;
      this.$http
        .get("/admin/banner-content/" + params)
        .then((response) => {
          this.formPayload = response.data.data;
          this.formPayload.image = this.formPayload.path;
          this.image_thumbnail = this.formPayload.path;
        })
        .then(() => {})
        .catch((error) => {
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.vueditor {
  height: 300px;
}
</style>
